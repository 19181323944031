.portraitPhoto {
  background: #000;
  aspect-ratio: 2/3;
  object-fit: cover;
  position: relative;
  overflow: hidden;
  width: 100%;
}

.hero .portraitPhoto, .card .portraitPhoto {
  border-radius: 0;
}

.college h2,
.college h3,
.college h4 {
  margin: 0;
}

.college a {
  text-decoration: none !important
}


.college .ui.card.ui.card {
  width: 271px !important;
  animation: fadeIn 1s ease-in-out;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.ui.styled.accordion, .ui.styled.accordion .accordion {
  width: 100% !important;
}

h3 {
  font-size: 20px;
  text-transform: capitalize;
}
